enum colors {
  PRIMARY = '#d12728',
  DARK_GREEN = '#166534',
  GREEN = '#4caf50',
  ACCENT_GREEN = '#a5d6a7',
  LIGHT_GREEN = '#dcfce7',
  DARK_RED = '#991b1b',
  RED = '#f42326',
  ACCENT_RED = '#ff5252',
  LIGHT_RED = '#fee2e2',
  WHITE = '#ffffff',
  BLACK = '#1c1c1d',
  LIGHT_GRAY = '#f1f1f1',
  GRAY = '#3d3d3d',
}

export default colors;
