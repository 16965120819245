import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import colors from '../constants/colors';
import { ReactComponent as NTSLogo } from '../resources/nts-logo.svg';

export const LoadingScreen = () => {
  return (
    <div>
      <main>
        <div className="w-screen h-screen flex justify-center items-center">
          <div className="w-full justify-center items-center flex flex-col space-y-2">
            <a href="/">
              <NTSLogo height={30} width={30} />
            </a>
            <FontAwesomeIcon color={colors.BLACK} icon={faSpinner} size="lg" spin={true} spinPulse={true} />
          </div>
        </div>
      </main>
    </div>
  );
};
