import { default as ax } from 'axios';

import { IS_DEV } from '../constants/env';

const axios = ax.create({
  baseURL: IS_DEV ? 'http://dev.ntslightning.com' : 'https://api.ntslightning.com',
});

axios.interceptors.request.use(
  (config) => {
    if (!config.url) {
      return config;
    }

    let currentUrl = new URL(config.url, config.baseURL);
    Object.entries(config.pathVars || {}).forEach(([k, v]) => {
      currentUrl = new URL(currentUrl.href.replace(`:${k}`, encodeURIComponent(v)));
    });
    return { ...config, url: currentUrl.href };
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
