import type { FC, PropsWithChildren } from 'react';

interface BlockProps {
  title?: string;
}

export const Block: FC<PropsWithChildren<BlockProps>> = ({ title, children }) => {
  return (
    <div className="pt-4">
      {title && (
        <div className="flex w-auto my-auto">
          <h2 className="text-xl font-bold py-4">{title}</h2>
        </div>
      )}
      {children}
    </div>
  );
};
