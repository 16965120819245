import { withAuthenticator } from '@aws-amplify/ui-react';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';

import { RedirectToHome } from './containers/RedirectToHome';
import { ScrollToTop } from './containers/ScrollToTop';
import { NavStateProvider } from './hooks/useNavState';
import { ReactComponent as NTSLogo } from './resources/nts-logo.svg';
import { DashboardScreen } from './screens/DashboardScreen';
import { DataExplorerScreen } from './screens/DataExplorerScreen';
import { StrategyScreen } from './screens/StrategyScreen';

const App = () => {
  return (
    <NavStateProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route element={<Navigate replace={true} to="/" />} path="*" />
          <Route element={<RedirectToHome />} path="/" />
          <Route element={<DashboardScreen />} path="/dashboard" />
          <Route element={<StrategyScreen />} path="/strategy/:strategy" />
          <Route element={<DataExplorerScreen />} path="/explore" />
        </Routes>
      </Router>
    </NavStateProvider>
  );
};

export default withAuthenticator(App, {
  hideSignUp: true,
  components: {
    Header: () => (
      <div className="flex justify-center mb-8">
        <a href="/">
          <NTSLogo height={60} width={60} />
        </a>
      </div>
    ),
  },
});
