import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import colors from '../constants/colors';

import type { Strategy } from '../types';

export type StrategyStackItemProps = {
  strategy: Strategy;
  isClickable?: boolean;
};

export const StrategyStackItem: FC<StrategyStackItemProps> = ({ strategy, isClickable = true }) => {
  const navigate = useNavigate();

  const strategyAvailability = strategy.termination_date !== null ? 'TERMINATED' : 'RUNNING';
  const strategyAvailabilityColor = strategyAvailability === 'RUNNING' ? colors.DARK_GREEN : colors.DARK_RED;
  const strategyAvailabilityBgColor = strategyAvailability === 'RUNNING' ? colors.LIGHT_GREEN : colors.LIGHT_RED;

  const handleViewDetails = (strategyName: string) => {
    navigate(`/strategy/${strategyName}`, { state: { strategy } });
  };

  const StrategyDetail = () => {
    return (
      <div>
        <div className="flex flex-row items-center justify-between">
          <div className="text-lg text-left font-semibold" style={{ maxLines: 1 }}>
            {strategy.strategy_name}
          </div>
        </div>
        <div className="text-left">
          <span
            className="px-2 inline-flex text-xs leading-5
                      font-semibold rounded-full"
            style={{ backgroundColor: strategyAvailabilityBgColor, color: strategyAvailabilityColor }}>
            {strategyAvailability}
          </span>
          <span className="text-sm">{` ON `}</span>
          <span>{strategy.termination_date ?? `<${strategy.documentation_link}>`}</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isClickable ? (
        <button
          className="shadow overflow-hidden border-b border-gray-200 rounded-lg p-4 bg-white w-full"
          onClick={() => handleViewDetails(strategy.strategy_name)}>
          <StrategyDetail />
        </button>
      ) : (
        <div className="overflow-hidden border border-gray-200 rounded-lg p-4 bg-white w-full">
          <StrategyDetail />
        </div>
      )}
    </div>
  );
};
