import axios from '../utils/axios';

import type { PNL } from '../types';

export const fetchStrategyPNL = async (strategy: string): Promise<Array<PNL>> => {
  const endpoint = `/strategy/get_strategy_pnl?strategy=${strategy}`;

  return axios
    .get<Array<PNL>>(endpoint)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
