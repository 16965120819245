import { useEffect, useState } from 'react';

import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faClose } from '@fortawesome/free-solid-svg-icons/faClose';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import colors from '../constants/colors';
import { useNavState, useSetNavState } from '../hooks/useNavState';
import { ReactComponent as NTSLogotype } from '../resources/nts-logotype.svg';

import './Topbar.css';

export const Topbar = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('unknown');
  const navigate = useNavigate();

  const navState = useNavState();
  const setNavState = useSetNavState();

  const handleTriggerMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const handleLogout = () =>
    Auth.signOut()
      .then(() => {
        navigate('/');
      })
      .catch((err) => {
        // show error toast message on page
        console.error(err);
      });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
        setShowMenu(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUsername(user.username);
    });
  });

  return (
    <header>
      <nav className="flex items-center justify-between flex-wrap bg-white px-8">
        <div className="flex items-center flex-shrink-0 text-white mr-8">
          <a href="/">
            <NTSLogotype />
          </a>
        </div>
        <div className="block md:hidden my-auto">
          <button className="flex items-center px-3 py-2 border rounded" id="menu-button" onClick={handleTriggerMenu}>
            {showMenu ? (
              <FontAwesomeIcon color={colors.GRAY} icon={faClose} width={12} />
            ) : (
              <FontAwesomeIcon color={colors.GRAY} icon={faBars} width={12} />
            )}
          </button>
        </div>
        <div
          className={`w-full block md:flex-grow md:flex md:items-center md:w-auto justify-between menu ${
            showMenu ? 'border-t menu-show' : 'menu-hidden'
          }`}>
          <div className="md:flex md:flex-row md:flex-grow-0 items-end">
            <div className="text-sm md:flex-grow">
              <a
                className={`menu-link block md:inline-block px-4 py-2 md:mt-0 mt-4 md:mr-4 rounded-lg ${
                  navState === 0 && 'menu-selected'
                } `}
                href="/dashboard"
                onClick={() => setNavState(0)}>
                 {'Dashboard'}
              </a>
            </div>
            <div className="text-sm md:flex-grow">
              <a
                className={`menu-link block md:inline-block px-4 py-2 md:mt-0 mt-2 md:mr-4 rounded-lg ${
                  navState === 1 && 'menu-selected'
                }`}
                href="/explore"
                onClick={() => setNavState(1)}>
                 {'Data Explorer'}
              </a>
            </div>
          </div>
          <div className="block mt-2 mb-6 md:mb-0 md:mt-0">
            <div className="flex flex-row items-center justify-between">
              {showMenu && <div className="text-sm px-4 text-gray-400">{`Username: ${username}`}</div>}
              <button className="py-2 px-6 rounded-lg" id="signout-button" onClick={handleLogout}>
                <div className="nav-link">
                  <FontAwesomeIcon icon={faRightFromBracket} style={{ marginRight: 8 }} />
                  <span>{'Sign out'}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
