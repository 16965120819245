const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const convertToCurrencyFormat = (value: number) => {
  return currencyFormatter.format(value);
};

export const convertToAccountingFormat = (value: number) => {
  if (value < 0) {
    return currencyFormatter.format(value).replace('-', '(').concat(')');
  } else {
    return currencyFormatter.format(value);
  }
};
