import type { FC } from 'react';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer } from 'recharts';

import { NoData } from './NoData';
import colors from '../constants/colors';
import { convertToCurrencyFormat } from '../utils/formatter';

import type { PNL } from '../types';

interface PNLChartProps {
  pnlData: Array<PNL>;
}

export const PNLChart: FC<PNLChartProps> = ({ pnlData }) => {
  return (
    <div className="overflow-hidden rounded-sm">
      {pnlData?.length > 0 ? (
        <div className="bg-white h-96 flex items-center justify-center">
          <ResponsiveContainer height="100%" width="100%">
            <BarChart
              data={pnlData}
              height={300}
              margin={{
                top: 16,
                right: 48,
                left: 48,
                bottom: 8,
              }}
              width={500}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" label={{ value: 'Date' }} tick={false} />
              <YAxis
                label={{ value: 'P&L', position: 'insideLeft', angle: -90, dy: 16, dx: -32 }}
                tickFormatter={(value) => convertToCurrencyFormat(value)}
              />
              <Tooltip formatter={(value) => convertToCurrencyFormat(parseInt(value.toString()))} />
              <ReferenceLine stroke={colors.GRAY} y={0} />
              <Bar dataKey="pnl" fill={colors.PRIMARY} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};
