import axios from '../utils/axios';

import type { HistoricalTrade } from '../types';

export const fetchHistoricalTrades = async (strategy: string, lookback = 0): Promise<Array<HistoricalTrade>> => {
  const endpoint = `/strategy/get_strategy_hist_trades?strategy=${strategy}&lookback=${lookback}`;

  return axios
    .get<Array<HistoricalTrade>>(endpoint)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
