import { useEffect } from 'react';

import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import { LoadingScreen } from '../screens/LoadingScreen';

export const RedirectToHome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const verifySession = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        return true;
      } catch {
        return false;
      }
    };
    verifySession().then((userExists) => {
      if (!userExists) {
        navigate('/');
      } else {
        navigate('/dashboard');
      }
    });
  }, [navigate]);

  return <LoadingScreen />;
};
