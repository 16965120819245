import { useEffect, useState } from 'react';

import { fetchActiveStrategies } from '../api/fetchActiveStrategies';
import { fetchPortfolioPNL } from '../api/fetchPortfolioPNL';
import { Block } from '../components/Block';
import { Footer } from '../components/Footer';
import { PNLChart } from '../components/PNLChart';
import { StrategyStack } from '../components/StrategyStack';
import { Topbar } from '../components/Topbar';
import { useSetNavState } from '../hooks/useNavState';

import type { PNL, Strategy } from '../types';

export const DashboardScreen = () => {
  const [activeStrategies, setActiveStrategies] = useState<Array<Strategy>>([]);
  const [portfolioPNL, setPortfolioPNL] = useState<Array<PNL>>([]);

  const setNavState = useSetNavState();

  useEffect(() => {
    setNavState(0);
  }, [setNavState]);

  useEffect(() => {
    fetchActiveStrategies()
      .then((activeStrategies) => {
        setActiveStrategies(activeStrategies);
      })
      .catch((err) => {
        // TODO: show error toast message and send error to Sentry
        console.error(err);
      });

    fetchPortfolioPNL()
      .then((portfolioPNL) => {
        setPortfolioPNL(portfolioPNL);
      })
      .catch((err) => {
        // show error toast message
        console.error(err);
      });
  }, []);

  return (
    <div>
      <Topbar />
      <main className="px-8">
        <div className="block w-full">
          <Block title="Portfolio Daily P&L">
            <PNLChart pnlData={portfolioPNL} />
          </Block>
          <Block title="Strategy Stack">
            <StrategyStack activeStrategies={activeStrategies} />
          </Block>
        </div>
      </main>
      <Footer />
    </div>
  );
};
