import { AmplifyProvider } from '@aws-amplify/ui-react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom/client';

import App from './App';
import config from './aws-exports';
import colors from './constants/colors';

import type { Theme } from '@aws-amplify/ui-react';

import './index.css';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(config);

const authTheme: Theme = {
  name: 'nts-theme',
  tokens: {
    components: {
      button: {
        color: 'var(--nts-color-primary)',
      },
      authenticator: {
        modal: {
          backgroundColor: '#fff',
        },
      },
    },
  },
};

const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.PRIMARY,
    },
    text: {
      primary: '#1F2937',
      secondary: colors.GRAY,
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    body1: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
    },
    body2: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ThemeProvider theme={muiTheme}>
    <AmplifyProvider theme={authTheme}>
      <App />
    </AmplifyProvider>
  </ThemeProvider>
);
