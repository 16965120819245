import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { fetchHistoricalTrades } from '../api/fetchHistoricalTrades';
import { fetchOpenTrades } from '../api/fetchOpenTrades';
import { fetchStrategyPNL } from '../api/fetchStrategyPNL';
import { fetchStrategyStatus } from '../api/fetchStrategyStatus';
import { Block } from '../components/Block';
import { Footer } from '../components/Footer';
import { HistoricalTradeTable } from '../components/HistoricalTradeTable';
import { OpenTradeTable } from '../components/OpenTradeTable';
import { PNLChart } from '../components/PNLChart';
import { StrategyStackItem } from '../components/StrategyStackItem';
import { Topbar } from '../components/Topbar';
import { useSetNavState } from '../hooks/useNavState';

import type { HistoricalTrade, PNL, Strategy, StrategyStatus, OpenTrade } from '../types';

export const StrategyScreen = () => {
  const { strategy: strategyName } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const setNavState = useSetNavState();

  const [strategyStatus, setStategyStatus] = useState<StrategyStatus | null>(null);
  const [historicalTrades, setHistoricalTrades] = useState<Array<HistoricalTrade>>([]);
  const [openTrades, setOpenTrades] = useState<Array<OpenTrade>>([]);
  const [strategyPNL, setStrategyPNL] = useState<Array<PNL>>([]);

  useEffect(() => {
    setNavState(0);
  }, [setNavState]);

  useEffect(() => {
    if (strategyName) {
      fetchStrategyStatus(strategyName)
        .then((strategyStatus) => {
          setStategyStatus(strategyStatus);
        })
        .catch((err) => {
          // TODO: show error toast message and send error to Sentry
          console.error(err);
        });

      fetchHistoricalTrades(strategyName)
        .then((historicalTrades) => {
          setHistoricalTrades(historicalTrades);
        })
        .catch((err) => {
          // TODO: show error toast message and send error to Sentry
          console.error(err);
        });

      fetchStrategyPNL(strategyName)
        .then((strategyPNL) => {
          setStrategyPNL(strategyPNL);
        })
        .catch((err) => {
          // TODO: show error toast message and send error to Sentry
          console.error(err);
          navigate('/');
        });

      fetchOpenTrades(strategyName)
        .then((openTrades) => {
          setOpenTrades(openTrades);
        })
        .catch((err) => {
          // TODO: show error toast message and send error to Sentry
          console.error(err);
        });
    }
  }, [navigate, strategyName]);

  if (!strategyName || !state) {
    return null;
    navigate('/');
  }

  return (
    <div>
      <Topbar />
      <main className="px-8">
        <div className="block w-full">
          <Block>
            <div className="mt-4">
              <StrategyStackItem isClickable={false} strategy={state.strategy as Strategy} />
            </div>
          </Block>
          <Block title="Strategy Daily P&L">
            <PNLChart pnlData={strategyPNL} />
          </Block>
          <Block title="Open Trades">
            <OpenTradeTable openTrades={openTrades} />
          </Block>
          <Block title="Recent Trades">
            <HistoricalTradeTable historicalTrades={historicalTrades} />
          </Block>
        </div>
      </main>
      <Footer />
    </div>
  );
};
