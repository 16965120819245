import axios from '../utils/axios';

import type { OpenTrade } from '../types';

export const fetchOpenTrades = async (strategy: string): Promise<Array<OpenTrade>> => {
  const endpoint = `/strategy/get_strategy_open_trades?strategy=${strategy}`;

  return axios
    .get<Array<OpenTrade>>(endpoint)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
