import axios from '../utils/axios';

import type { Strategy } from '../types';

export const fetchActiveStrategies = async (): Promise<Array<Strategy>> => {
  const endpoint = '/port/get_active_strategies';

  return axios
    .get<Array<Strategy>>(endpoint)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
