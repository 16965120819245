export const Footer = () => {
  return (
    <footer>
      <div className="w-full">
        <div className="container px-8 my-4">
          <div className="py-4 text-sm text-gray-400">{'© Northeastern Trading Systems 2023'}</div>
        </div>
      </div>
    </footer>
  );
};
