import type { FC } from 'react';

import { NoData } from './NoData';
import { StrategyStackItem } from './StrategyStackItem';

import type { Strategy } from '../types';

interface StrategyStackProps {
  activeStrategies: Array<Strategy>;
}

export const StrategyStack: FC<StrategyStackProps> = ({ activeStrategies }) => {
  return (
    <div>
      {activeStrategies?.length > 0 ? (
        <ul>
          {activeStrategies.map((strategy, id) => (
            <li className={id === 0 ? '' : 'mt-4'} key={id}>
              <StrategyStackItem strategy={strategy} />
            </li>
          ))}
        </ul>
      ) : (
        <NoData />
      )}
    </div>
  );
};
