import axios from '../utils/axios';

import type { StrategyStatus } from '../types';

export const fetchStrategyStatus = async (strategy: string): Promise<StrategyStatus> => {
  const endpoint = `/strategy/get_strategy_status?strategy=${strategy}`;

  return axios
    .get<StrategyStatus>(endpoint)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
