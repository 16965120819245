import { useState } from 'react';

import constate from 'constate';

const useNavStateContext = () => {
  const [navState, setNavState] = useState<number>(0);
  return { navState, setNavState };
};

export const [NavStateProvider, useNavState, useSetNavState] = constate(
  useNavStateContext,
  (value) => value.navState,
  (value) => value.setNavState
);
