/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from 'react';

import { Box, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ExportToCsv } from 'export-to-csv';

import { DataTable } from './DataTable';
import colors from '../constants/colors';
import { convertToAccountingFormat } from '../utils/formatter';

import type { OpenTrade } from '../types';
import type { TextFieldProps } from '@mui/material';
import type { Options as CsvOptions } from 'export-to-csv';
import type { MRT_ColumnDef } from 'material-react-table';

const columns: MRT_ColumnDef<OpenTrade>[] = [
  {
    accessorKey: 'trade_id',
    id: 'trade-id',
    header: 'Trade ID',
    size: 50,
  },
  {
    accessorFn: (row) => new Date(row.open_time),
    id: 'open-time',
    header: 'Open Time',
    filterFn: (row, id, filterValue) =>
      new Date(row.getValue('open-time')).getTime() >= new Date(filterValue).getTime(),
    sortingFn: 'datetime',
    size: 200,
    Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleString(),
    Filter: ({ column }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          onChange={(newValue: string | null) => {
            column.setFilterValue(newValue);
          }}
          renderInput={(params: TextFieldProps) => (
            <TextField
              {...params}
              helperText="Filter Mode: Greater Than"
              sx={{ minWidth: '120px' }}
              variant="standard"
            />
          )}
          value={column.getFilterValue()}
        />
      </LocalizationProvider>
    ),
  },
  {
    accessorKey: 'contract',
    id: 'contract',
    header: 'Contract',
    size: 50,
    filterVariant: 'text',
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={() => ({
          backgroundColor: colors.LIGHT_GRAY,
          borderRadius: '0.25rem',
          color: colors.BLACK,
          maxWidth: '9ch',
          p: '0.25rem',
        })}>
        {cell.getValue<string>()}
      </Box>
    ),
  },
  {
    accessorKey: 'no_legs',
    id: 'no-legs',
    header: 'No. Legs',
    size: 50,
  },
  {
    accessorKey: 'fills',
    id: 'fills',
    header: 'Fills',
    size: 50,
  },
  {
    accessorKey: 'capital_out',
    id: 'capital-out',
    header: 'Capital Out',
    filterVariant: 'range',
    size: 300,
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          backgroundColor:
            cell.getValue<number>() < 0
              ? theme.palette.error.main
              : cell.getValue<number>() === 0
              ? colors.GRAY
              : theme.palette.success.main,
          borderRadius: '0.25rem',
          color: colors.WHITE,
          maxWidth: '9ch',
          p: '0.25rem',
        })}>
        {convertToAccountingFormat(cell.getValue<number>())}
      </Box>
    ),
  },
];

const csvOptions = {
  filename: `${new Date().toJSON().slice(0, 10)}_nts-open-trades`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
} as CsvOptions;

const csvExporter = new ExportToCsv(csvOptions);

interface OpenTradesProps {
  openTrades: Array<OpenTrade>;
}

export const OpenTradeTable: FC<OpenTradesProps> = ({ openTrades }) => {
  return <DataTable columns={columns as any} csvExporter={csvExporter} data={openTrades} />;
};
