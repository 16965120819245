import { useEffect } from 'react';

import { Footer } from '../components/Footer';
import { Topbar } from '../components/Topbar';
import { useSetNavState } from '../hooks/useNavState';

export const DataExplorerScreen = () => {
  const setNavState = useSetNavState();

  useEffect(() => {
    setNavState(1);
  }, [setNavState]);

  return (
    <div>
      <Topbar />
      <main className="px-8">
        <div className="block w-full">
          <div className="flex h-24 items-center justify-center">{'This feature is not available yet!'}</div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
