/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from 'react';
import { useCallback } from 'react';

import { faFileExport } from '@fortawesome/free-solid-svg-icons/faFileExport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@mui/material';
import MaterialReactTable from 'material-react-table';

import { NoData } from './NoData';
import colors from '../constants/colors';

import type { ExportToCsv } from 'export-to-csv';
import type { MRT_ColumnDef, MRT_Row } from 'material-react-table';

interface DataTableProps {
  columns: MRT_ColumnDef<any>[];
  data: Array<any>;
  csvExporter: ExportToCsv;
}

export const DataTable: FC<DataTableProps> = ({ columns, data, csvExporter }) => {
  const handleExportRows = (rows: MRT_Row<any>[]) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = useCallback(() => {
    csvExporter.generateCsv(data);
  }, [data, csvExporter]);

  return (
    <div>
      {data?.length === 0 ? (
        <NoData />
      ) : (
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnFilterModes={true}
          enableColumnOrdering={true}
          enableGrouping={true}
          enablePinning={true}
          enableRowSelection={true}
          enableStickyHeader={true}
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
              <Button
                color="primary"
                onClick={handleExportData}
                startIcon={<FontAwesomeIcon color={colors.WHITE} icon={faFileExport} />}
                sx={{ fontSize: '0.75rem' }}
                variant="contained">
                {'Export All Data'}
              </Button>
              <Button
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                startIcon={<FontAwesomeIcon color={colors.WHITE} icon={faFileExport} />}
                sx={{ fontSize: '0.75rem' }}
                variant="contained">
                {'Export Selected Data'}
              </Button>
            </Box>
          )}
        />
      )}
    </div>
  );
};
